// Here you can add other styles
.labels-style {
  margin-right: 1rem;
  align-self: center;
  width: 7rem;
  margin-bottom: 8px;
}
.top,
.budget-modal {
  z-index: 1055;
  background-color: rgba(0, 0, 0, 0.4);
    .modal-dialog {
      width: 55rem;
      border-radius: 20px;
      box-sizing: border-box;
      position: relative;
    }

  .modal-dialog::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    border-radius: 20px;
    background: linear-gradient(to right bottom, transparent, rgba(0, 0, 0, .7));
  }
}
.budget-modal {
  .modal-dialog {
    width: 80rem;
  }
}
.form_modal,
.small_modal {
  display: flex;
  .btn-close {
    --cui-btn-close-bg: url("../assets/images/fca/close.png");
    --cui-btn-close-opacity: 1;
  }
  .card {
    border: none;
  }
  .modal-dialog {
    max-width: 75rem;
  }
  .modal-content {
    border-radius: 1.2rem;
    color: #091C14;
    font-weight: 500;
    .uploads {
      margin: 3rem 0 10rem 0;
    }
    .dark-grey-bg,
    .yellow-bg,
    .red-bg {
      input {
        background-color: #84ABA5;
        font-weight: 600;
        text-align: center;
      }
    }
    .yellow-bg input {
      background-color: #EBFF99;
    }
    .red-bg input {
      background-color: #FF7373;
    }
    .blue-bg,
    .grey-bg {
      text-align: center;
      label {
        text-decoration: underline;
      }
      input {
        background-color: #E9F7FD;
      }
    }
    .grey-bg {
      input {
        background-color: #d9d9d9;
      }
    }
    .input-right {
      color: #091C14;
      font-weight: 700;
      font-size: 16px;
      position: absolute;
      top: 2.7rem;
      right: 4rem;
    }
    .budget-input {
      th:nth-child(2) {
        min-width: 250px;
        text-align: left;
      }
      td {
        color: #091C14;
      }
      th {
        position: relative;
        text-align: center;
        span {
          line-height: 0rem;
          font-size: 12px;
          position: absolute;
          text-align: center;
          left: 0;
          bottom: 8px;
          width: 100%;
        }
      }
      ::placeholder {
        text-align: center;
     }
    }
    .responsive-table-cols {
      th:first-child {
        min-width: 200px;
        text-align: left;
      }
      td {
        color: #091C14;
      }
      th {
        min-width: 120px;
        text-align: center;
      }
    }
    .new-table,
    .new-table-docs {
      tr {
        border: transparent;
        line-height: 50px;
      }
      td {
        ul {
          max-width: 25rem;
          list-style-type: none;
          padding: 0 0 0 1rem;
          div {
            display: flex;
            justify-content: space-between;
            li {
              display: table-cell;
              width: 10rem;
            }
          }
        }
      }
      .switch {
        margin-top: .5rem;
        .form-check-input {
          height: 1.1rem;
          width: 2.5rem;
          margin-right: 0.5rem;
        }
        .form-check-input:checked {
          background-color: #2F99C7;
          border-color: #2F99C7;
        }
      }
    }
    .new-table-docs {
      td {
        ul {
          max-width: 35rem;
          div {
            li {
              width: 15rem;
            }
          }
        }
      }
    }
    .next {
      width: 116px;
      height: 46px;
      border-radius: 16px;
      background: $layout-color;
      border: none;
      font-weight: 500;
    }
    .split,.non-split {
      background: #cfedfa;
      width: 86px;
      height: 38px;
      border-radius: 1rem;
      color: #2F99C7;
      margin-right: .5rem;
      border-top-right-radius: 1rem !important;
      border-bottom-right-radius: 1rem !important;
    }
    .success {
      color: #3DBC59;
    }
    .error {
      color: #C33F11;
    }
    .tb-bg {
      --cui-table-bg: #e9f7fd;
      table {
        --cui-table-bg: #e9f7fd;
      }
    }
    .switch {
      .form-check-input {
        height: 1.1rem;
        width: 2.5rem;
        margin-right: 0.5rem;
      }
      .form-check-input:checked {
        background-color: #2F99C7;
        border-color: #2F99C7;
      }
    }
    .non-split {
      background: white;
    }
    .arrow {
      position: absolute;
    }
    .arrow-style {
      padding-left: 9rem;
      position: relative;
      img,p {
        position: absolute;
        right: 2rem;
        top: 6px;
      }
      p {
        right: .5rem;
        color: #909090;
        font-size: 1rem;
      }
    }
    .modal-title {
      font-size: 22px;
      font-weight: 600;
    }
    .fw-bold {
      font-size: 18px;
    }
    .form-control,
    .react-select-form-control {
      min-height: 3rem;
      border-radius: 12px;
      margin-bottom: 8px;
    }
    .is-valid .react-select-form-control,
    .is-invalid .react-select-form-control {
      background-position: bottom 15px right 28px;
    }
  }
  .react-tabs__tab--selected {
    background: none;
    border-color: transparent;
    border-bottom: 2px solid $layout-color;
    font-weight: 700;
    color: $layout-color;
  }
  .react-tabs__tab {
    font-size: 20px;
    background: transparent;
    border: 0;
    margin: 0 1rem 0 0;
    font-weight: 500;
    padding: 0px 12px;
  }
  .react-tabs__tab--selected::after {
    background: $layout-color;
    content: "";
    display: block;
    height: 4px;
    margin-top: 8px;
    width: 25px;
  }
  .react-tabs__tab-list {
    display: flex;
  }
}
.draw_modal {
  .modal-dialog {
    max-width: 81rem;
  }
}
.bordered {
  padding: 2rem !important;
  .modal-content {
    border-radius: 0;
  }
  .new-table {
    thead, tbody, tfoot, tr, td, th {
      border-width: 1px;
      padding: 0 10px;
    }
    tr {
      border: 1px solid !important;
    }
  }
}
.small_modal {
 .modal-dialog {
  max-width: 35rem;
 }
}
.nav-group-items {
  margin-left: .7rem;
}

.modal-scrollable {
  max-height: 550px;
  overflow-y: auto;
}
.sidebar.right:not(.sidebar-end) {
  right: 0 !important;
  left: auto !important;
}

input[type="file"] {
  display: none;
}
.file input[type="file"],
.files input[type="file"]{
  display: block;
  min-height: 2.4rem !important;
  height: 2rem;
}

.files input[type="file"] {
  min-height: auto !important;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 0px 12px;
  cursor: pointer;
}

// .right.hide:not(.sidebar-end) {
//   margin-right: calc(-1 * var(--cui-sidebar-width) - 20rem) !important;
// }
// .sidebar.right {
//   width: 35rem;
//   height: max-content;
// }
.is-invalid .react-select-form-control {
  border-color: var(--cui-form-invalid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23e55353%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23e55353%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-position: bottom 6px right 30px;
}

.is-valid .react-select-form-control {
  border-color: var(--cui-form-valid-border-color);
  background-image: url("../assets//images/loader.gif");
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-position: bottom 6px right 30px;
}
.document-valid {
  border-color: var(--cui-form-valid-border-color);
}
.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("../assets//images/loader.gif");
}
.total-suggestions .react-select-form-control {
  border-color: deeppink;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%232eb85c%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-position: bottom 6px right 30px;
}

body div#root > div {
  // background-image: url('../assets/images/backgrounds/youssef-abdelwahab-qyzo7TDSVQs-unsplash.jpg') !important;
  background-position: center;
  background-size: cover;
}

.bg-light {
  background-color: rgba(235, 237, 239, 0.90) !important;
}

.funds-control-account-select__option, .funds-control-account-select__single-value{
  white-space: pre-wrap !important;
}

.date-range {
  margin-left: 0;
  padding: .7rem 1rem;
  border-radius: 14px;
  span {
    font-size: 18px;
    font-weight: 600;
    margin-right: 2.5rem;
  }
}
.rdrDateRangePickerWrapper {
  position: absolute;
  z-index: 2;
  right: 0;
  top: -1rem;
}
.hide {
  .rdrDateRangePickerWrapper {
    display: none;
  }
}

.react-datepicker-wrapper {
  display: inline !important;
}

.invalid-feedback {
  display: block;
}

.border-around {
  border: 1px solid #ccc;
  padding: 1rem;
}
.sub-section {
  background-color: $layout-color;
  padding: 0.5rem;
  text-align: center;
  margin: 2rem 0;
  color: #fff;
}
.form-select {
  height: 30px;
  font-size: .9rem;
}
.fca-form {
  .form-label {
    margin: 0;
  }
}
// select {
//   -webkit-appearance: listbox !important;
// }

.mail {
  .body {
    border: 1px solid #ccc;
    padding: 1rem;
    // margin: 2rem 0;
    background: #ececec;
    .subject {
      font-weight: 700;
    }
    .main {
      margin: 1rem 0;
      padding: 1rem;
      font-size: 14px;
      color: #6a6262;
    }
  }
}

.file-budget {
  input[type=file] {
    display: block;
  }
}

// Listings Page
.listing {
  border-radius: 1rem;
  .card-body {
    padding: 0;
    .rdt_TableHeadRow,
    thead tr th{
      color: #909090;
      font-size: 1rem;
    }
    .rdt_TableRow,
    tbody tr td {
      font-size: 1rem;
      color: $layout-color;
      font-weight: 500;
      .btn {
        border: 0;
        margin-right: 10px;
        border-radius: 8px !important;
      }
      .send {
        background: #004D3B;
      }
      .edit {
        background: #D2E0DD;
      }
      .delete {
        background: rgba(195, 63, 17, 0.1);
      }
      .mail {
        background: #49c3e7;
      }
    }
  }
}

// React Select
.swal2-popup code {
  background-color: red;
  color: white;
  border-radius: 5px;
  font-size: 0.775em;
  padding: 5px;
  font-family: inherit;
}

.search button.clear-btn {
  background-color: transparent;
  background-image: url("../assets/images/fca/close.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  height: 12px;
  right: 60px;
  position: absolute;
  top: 12px;
  width: 12px;
  display: none;
}

.search input.in-use ~ button.clear-btn {
  display: block;
}

.input-right-hint {
  position: absolute;
  top: 3px;
  right: 45px;
  color: #808080;
  font-size: 12px;
}

*:has(> .input-right-hint) {
  position: relative;
}

.progress-line {
  line-height: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-width: 5px;
  max-width: 100%;
  color: #025346;
  border-color: #025346;
}

// Docs Splitter
.files-splitter-container {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: #02534682;
  z-index: 9999;

  .cls-btn {
    position: absolute;
    top: .8rem;
    right: 1.7rem;
    width: 2rem;
  }

  .row {
    background-color: transparent;
    height: 100%;
    padding: 0;
  }

  .pdf-docs {
    width: 50%;
    padding: 1rem;
  }

  .party-split > div:first-of-type {
    width: 100%;
  }

  .docs-form {
    background-color: #292a2d;
    color: #fff;
    padding: 2rem 1.5rem;
    min-height: 100vh;

    label {
      width: 10rem;
    }

    select,
    input ,
    .react-select-form-control{
      background-color: #000;
      color: #fff;
    }
    ::placeholder {
      color: #fff;
      opacity: 1; /* Firefox */
    }
  }
}
[data-column-id="Actions"].rdt_TableCol {
  display: flex;
  justify-content: center;
}

// Calendar Icon
.react-datepicker__input-container .react-datepicker__calendar-icon {
  top: 1rem;
  right: 2.3rem;
  padding: 0;
}

.custom-bg-primary{
  background-color: $layout-color;
}

.tooltip-main {
  position: relative;
}

.tooltip-main .tooltip-text {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 7px;
  position: absolute;
  z-index: 1;
  bottom: 2.5rem;
  right: 30%;
}

.tooltip-main:hover .tooltip-text {
  visibility: visible;
}

td:has(.switch .form-check-input) {
  vertical-align: middle;
}
.fca-form {
  .form-control-sm {
    min-height: 3rem;
    border-radius: 12px;
    margin-bottom: 8px;
  }
  label {
    margin-bottom: 0.5rem;
  }
  .form-check-input {
    height: 1.1rem;
    width: 1.5rem;
    margin-right: 0.5rem;
  }
  .form-check-input:checked {
    background-color: #2F99C7;
    border-color: #2F99C7;
  }
  .form-check-inline .form-check-input,
  .check .form-check-input {
    height: 1rem;
    width: 1rem;
  }
  .sub-section {
    .form-control-sm {
      min-height: 1rem;
      margin-bottom: 2px;
    }
  }
  .next {
    width: 116px;
    height: 46px;
    border-radius: 16px;
    background: $layout-color;
    border: none;
    font-weight: 500;
  }
}
.login {
  width: 100%;
  height: 46px;
  border-radius: 16px;
  background: $layout-color;
  border: none;
  font-weight: 500;
}
.login:hover {
  background: $layout-color;
}
.permission-list {
  .nav-item {
    width: 100% !important;
    .nav-link {
      color: $layout-color;
    }
    .nav-link:hover {
      background: $layout-color;
      color: #fff;
      border-radius: 10px;
    }
    .active {
      background: $layout-color;
      color: #fff;
      border-radius: 10px;
    }
  }
  tr:hover td {
    background: $layout-color;
    color: #fff !important;
  }
}
.tools {
  .btn {
    margin-right: .2rem;
    width: 7rem;
  }
}

// Activity Log design in receipts
.activity-feed {
  padding: 15px;
}
.activity-feed .feed-item {
  position: relative;
  padding-bottom: 20px;
  padding-left: 30px;
  border-left: 2px solid #e4e8eb;
}
.activity-feed .feed-item:last-child {
  border-color: transparent;
}
.activity-feed .feed-item:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -6px;
  width: 10px;
  height: 10px;
  border-radius: 6px;
  background: #fff;
  border: 1px solid #f37167;
}
.activity-feed .feed-item .date {
  position: relative;
  top: -5px;
  color: #8c96a3;
  text-transform: uppercase;
  font-size: 13px;
}
.activity-feed .feed-item .text {
  position: relative;
  top: -3px;
}
.activity-pagination {
  .next {
    background: transparent !important;
    width: 3rem !important;
    height: 100% !important;
  }
}
.fca_modal {
  .modal-dialog {
    max-width: 97rem;
  }
}

.privacy {
  h2 {
    font-weight: 700;
    font-size: 30px;
    margin: 2rem 0 1.5rem 0;
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
  }
  p, li {
    color: #333333cc;
    line-height: 1.7rem;
  }
  li {
    line-height: 2rem;
  }
}

// Draw Pending Items
.draw-pending-items {
  padding: 20px;

  .category {
    padding: 5px;
    border-radius: 5px;

    .category-name {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 10px;
      color: #004D3B;
    }

    .checklist-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      input[type="checkbox"] {
        margin-left: 1rem;
        accent-color: #dde8e5;
        transform: scale(1.6);
      }

      .item-description {
        flex-grow: 1;
        border: 1px solid #c2bfbf;
        color: #909090;
        border-radius: 1rem;
        padding: .3rem .6rem;
      }

      .payments {
        margin-left: 20px;
        min-width: 90%;
        margin: 5px auto;

        .payment-info {
          font-size: 0.9em;
          color: #000;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

html:not([dir="rtl"]) .modal-header .btn-close {
  margin: 0;
}
.draw_modal {
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu.show {
    transform: translate(5%, 40px) !important;
    width: 65rem;
    inset: 0px 0px auto auto !important;
  }
}

.react-select-form-control[aria-disabled]{
  background-color: var(--cui-secondary-bg);
  opacity: 1;
}
