.pagination {
  list-style: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.5rem 0;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  li {
    border: 1px solid #F8F7FB;
    padding: 0;
    border-radius: 8px;
    margin-right: 0.4rem;
    a {
      text-decoration: none;
      color: #32323B;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: .6rem 1.1rem;
    }
  }
  li.selected {
    background-color: $layout-color;
    color: #fff;
    border-radius: 10px;
  }
  li.disabled {
    a {
      svg,img {
        opacity: .9;
      }
    }
  }
}

