.filters-style {
  width: 20rem;
  margin-left: auto;
  margin-right: 1.5rem;
  label {
    color: #909090;
  }
  select, .btn , input {
    border-radius: 8px !important;
    height: 38px;
  }
  select {
    font-size: 18px;
    font-weight: 600;
  }
  .btn {
    background: none;
    border: 1px solid $layout-color;
    img {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}
