.fca {
  .react-tabs__tab-list {
    border: 0;
    display: flex;
    align-items: baseline;
    .filters {
      margin-left: auto;
      margin-right: 1.5rem;
      label {
        color: #909090;
      }
      select, .btn , input {
        border-radius: 8px !important;
        height: 38px;
      }
      select {
        font-size: 18px;
        font-weight: 600;
      }
      .btn {
        background: none;
        border: 1px solid $layout-color;
        img {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }
    .react-tabs__tab {
      font-size: 24px;
      background: transparent;
      border: 0;
      font-weight: 500;
    }
    .react-tabs__tab--selected {
      font-weight: 600;
      color: $layout-color;
      // border-bottom: 4px solid $layout-color;
    }
    .react-tabs__tab--selected::after {
      background: $layout-color;
      content: "";
      display: block;
      height: 4px;
      margin-top: 8px;
      width: 35px;
  }
  }
}

.filters {
  margin-left: auto;
  margin-right: 1.5rem;
  .react-select-form-control {
    border-radius: 8px;
    background: white;
    font-size: 18px;
    font-weight: 600;
    min-width: 17rem;
    height: 2.4rem;
  }
  label {
    color: #909090;
  }
  select, .btn , input {
    border-radius: 8px !important;
    height: 38px;
  }
  select {
    font-size: 18px;
    font-weight: 600;
  }
  .btn {
    background: none;
    border: 1px solid $layout-color;
    img {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}
